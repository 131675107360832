/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import Table from '../../components/Table';
import { useRequestInfinite } from '../../hooks/useRequestInfinite';
import {
  deleteUsuario,
  getUsersInfinite,
  redefinirSenha,
} from '../../services/Users';
import { Icons } from '../../utils/icons';
import Toast from '../../components/Toast';
import Modal from '../../components/Modal';
import { useHeader } from '../../context/Header';
import { getAtorByKey } from '../../services/Ator';
import { Input } from '../../components/FormField/Input';

interface FilterFormData {
  search: string;
}

export interface DeleteUsuariosFormData {
  username: string;
  empresaKey: string;
}

const GerenciamentoUsuarios: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [search, setSearch] = useState('');
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState<string>('');

  const [ator, setAtor] = useState<AtorResult>();

  const getAtor = useCallback(async () => {
    const response = await getAtorByKey(id!);
    if (response.type === 'success') {
      setAtor(response.value);
    }
  }, [id]);

  useEffect(() => {
    getAtor();
  }, [getAtor, id]);

  const { refresh } = useHeader();

  const { data, isReachingEnd, fetchMore, isLoadingMore, mutate } =
    useRequestInfinite<PaginatedResult<ListUsersResponse[]>>(
      (_, previousPageData) => {
        return getUsersInfinite({
          bookmark: previousPageData?.data?.bookmark,
          empresaId: id,
        });
      },
      { limit: 10, dataPath: 'data' },
    );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FilterFormData>({
    defaultValues: {
      search: '',
    },
  });

  const {
    handleSubmit: deleteHandleSubmit,
    formState: { isSubmitting: deleteUserSubmitting },
  } = useForm();

  const {
    register: resetPasswordRegister,
    handleSubmit: resetPasswordHandleSubmit,
    watch,
    reset,
    formState: {
      isSubmitting: resetPasswordSubmitting,
      errors: resetPasswordErrors,
    },
  } = useForm<RedefinirSenhaPayload>();

  const password = useRef({});
  password.current = watch('newPassword', '');

  const filteredData = useMemo(() => {
    if (search !== '') {
      return data?.[0].data?.filter((item) =>
        item.nome.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    }
    return data?.[0].data;
  }, [data, search]);

  const deleteUsuarioHandler = useCallback(async () => {
    const username = selectedUsername;
    const payload: DeleteUsuariosFormData = {
      username,
      empresaKey: id || '',
    };
    const response = await deleteUsuario(payload);
    if (response.type === 'success') {
      toast(
        <Toast
          type="success"
          title="Usuário apagado com sucesso"
          description=""
        />,
      );
      refresh();
      mutate();
      setDeleteUserModalOpen(false);
    } else {
      toast(
        <Toast
          type="danger"
          title="Erro ao apagar usuário"
          description={response.error.message}
        />,
      );
    }
  }, [id, mutate, refresh, selectedUsername]);

  const resetPasswordSubmitHandler = useCallback(
    async (formData: RedefinirSenhaPayload) => {
      const payload: RedefinirSenhaPayload = {
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
        userName: selectedUsername,
        empresaKey: id || '',
      };
      const response = await redefinirSenha(payload);
      if (response.type === 'success') {
        toast(
          <Toast
            type="success"
            title="Senha redefinida com sucesso"
            description=""
          />,
        );
        refresh();
        mutate();
        setResetPasswordModalOpen(false);
        reset();
      } else {
        toast(
          <Toast
            type="danger"
            title="Erro ao redefinir senha"
            description={response.error.message}
          />,
        );
      }
    },
    [id, mutate, refresh, reset, selectedUsername],
  );

  const deleteUserModalArgs = {
    title: 'Excluir usuário',
    onClose: {
      action: () => setDeleteUserModalOpen(false),
      label: 'Cancelar',
    },
    onConfirm: {
      action: deleteHandleSubmit(deleteUsuarioHandler),
      label: 'Confirmar',
      loading: deleteUserSubmitting,
    },
  };

  const resetPasswordModalArgs = {
    title: 'Redefinir senha',
    onClose: {
      action: () => setResetPasswordModalOpen(false),
      label: 'Cancelar',
    },
    onConfirm: {
      action: resetPasswordHandleSubmit(resetPasswordSubmitHandler),
      label: 'Resetar',
      loading: resetPasswordSubmitting,
    },
  };

  const renderDataItems = () => {
    if (filteredData?.length! > 0) {
      return filteredData?.map((item, idx) =>
        item ? (
          <tr key={idx}>
            <td>{item.nome}</td>
            <td>{item.userName}</td>
            <td>{item.adminOrganization && <i className="fas fa-check" />}</td>
            <td>
              <Button
                variant="circle"
                title="Deletar usuário"
                onClick={() => {
                  setDeleteUserModalOpen(true);
                  setSelectedUsername(item.userName);
                }}
              >
                <i className="fas fa-trash" aria-hidden="true" />
              </Button>
              <Button
                variant="circle"
                title="Resetar senha"
                onClick={() => {
                  setResetPasswordModalOpen(true);
                  setSelectedUsername(item.userName);
                }}
              >
                <i className="fas fa-edit" aria-hidden="true" />
              </Button>
            </td>
          </tr>
        ) : (
          <></>
        ),
      );
    }
    return null;
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>{ator?.data.nome}</h1>
            </div>
            <Button
              onClick={() => navigate(`/usuarios/registrar/${id}`)}
              variant="primary"
            >
              <i className={Icons.NOVO_USUARIO} aria-hidden="true" />
              Registrar novo usuário
            </Button>
          </div>
          <Table
            thead={[
              'Nome do Usuário',
              'Login de Acesso',
              'Administrador',
              'Ações',
            ]}
            caption="Lista de Usuários"
            fetchMore={fetchMore}
            hasMore={isReachingEnd}
            isLoading={isLoadingMore}
            data={data}
          >
            {renderDataItems()}
          </Table>
          <Modal
            isOpen={deleteUserModalOpen}
            {...deleteUserModalArgs}
            onConfirm={{
              ...deleteUserModalArgs.onConfirm,
              buttonType: 'submit',
            }}
          >
            <p>Deseja confirmar a exclusão do usuário: {selectedUsername}?</p>
          </Modal>
          <Modal
            isOpen={resetPasswordModalOpen}
            {...resetPasswordModalArgs}
            onConfirm={{
              ...resetPasswordModalArgs.onConfirm,
              buttonType: 'submit',
            }}
          >
            <p>Redefinir senha do usuário {selectedUsername}</p>
            <form
              onSubmit={resetPasswordHandleSubmit(resetPasswordSubmitHandler)}
            >
              <Input
                label="Senha"
                type="password"
                {...resetPasswordRegister('newPassword', {
                  required: 'Este campo é obrigatório',
                  minLength: {
                    value: 8,
                    message: 'O número mínimo de caracteres é 8.',
                  },
                  maxLength: {
                    value: 16,
                    message: 'O número máximo de caracteres é 16.',
                  },
                })}
                className="mb-3"
                icon="fas fa-bars"
                placeholder="Inserir a senha"
                errors={resetPasswordErrors.newPassword}
              />
              <Input
                label="Confirmação de senha"
                type="password"
                {...resetPasswordRegister('confirmPassword', {
                  validate: (value) =>
                    value === password.current || 'As senhas devem coincidir',
                  required: 'Este campo é obrigatório',
                  minLength: {
                    value: 8,
                    message: 'O número mínimo de caracteres é 8.',
                  },
                  maxLength: {
                    value: 16,
                    message: 'O número máximo de caracteres é 16.',
                  },
                })}
                className="mb-3"
                icon="fas fa-bars"
                placeholder="Inserir a senha"
                errors={resetPasswordErrors.confirmPassword}
              />
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default GerenciamentoUsuarios;
