/* eslint-disable no-param-reassign */
import { useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { useAuth, userType } from '../context/Auth';
import { PrivateRoutes } from '../routes/PrivateRoutes';
import { IRoutes } from '../routes/types';
import { PublicRoutes } from '../routes/PublicRoutes';

export const useAuthorizedRoutes = () => {
  const { user } = useAuth();

  const getAuthorizedRoutes = useCallback(
    (routes: IRoutes[]) => {
      if (!user) return PublicRoutes.filter((route) => !route.hidden);

      const filteredRoutes = routes.filter((route) => {
        if (route?.children && !route.hidden) {
          route.children = getAuthorizedRoutes(route.children);
        }

        return route?.authorizedRoles?.includes(userType.get(user.Type)!);
      });

      return filteredRoutes;
    },
    [user],
  );

  return getAuthorizedRoutes(cloneDeep(PrivateRoutes));
};
