import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import GerenciamentoUsuarios from '../pages/Usuarios';
import RegistrarUsuario from '../pages/Usuarios/Register';
import { Icons } from '../utils/icons';
import { IRoutes } from './types';

const Dashboard = lazy(() => import('../pages/Dashboard'));

// const AdminDashboard = lazy(() => import('../pages/Dashboard/Admin'));
// const TransportadorDashboard = lazy(
//   () => import('../pages/Dashboard/Transportador'),
// );
// const CarregadorDashboard = lazy(() => import('../pages/Dashboard/Carregador'));
// const ReguladorDashboard = lazy(() => import('../pages/Dashboard/Regulador'));

const Transportadores = lazy(() => import('../pages/Transportadores'));
const RegistrarTransportadores = lazy(
  () => import('../pages/Transportadores/Register'),
);

const Carregadores = lazy(() => import('../pages/Carregadores'));
const RegistrarCarregadores = lazy(
  () => import('../pages/Carregadores/Register'),
);

const Gasodutos = lazy(() => import('../pages/Gasodutos'));
const RegistrarGasodutos = lazy(() => import('../pages/Gasodutos/Register'));

const PontosTransporte = lazy(() => import('../pages/PontosTranporte'));
const RegistrarPontosTransporte = lazy(
  () => import('../pages/PontosTranporte/Register'),
);

const Programacoes = lazy(() => import('../pages/Programacoes'));
const DashboardProgramações = lazy(
  () => import('../pages/Programacoes/Dashboard'),
);
const RegistrarProgramacoes = lazy(
  () => import('../pages/Programacoes/Register'),
);
const DetailsProgramacoes = lazy(() => import('../pages/Programacoes/Details'));

const SaldoPortfolio = lazy(() => import('../pages/SaldoPortfolio'));

const RegistroInjecoes = lazy(() => import('../pages/Registros/Injecoes'));
const RegistroRetiradas = lazy(() => import('../pages/Registros/Retiradas'));
const RegistroTransferencias = lazy(
  () => import('../pages/Registros/Transferencias'),
);

const Contratos = lazy(() => import('../pages/Contratos'));
const RegistrarContratos = lazy(() => import('../pages/Contratos/Register'));
const DashboardContratos = lazy(() => import('../pages/Contratos/Dashboard'));
const DetalhesContratos = lazy(() => import('../pages/Contratos/Details'));

const Nominacoes = lazy(() => import('../pages/Nominacoes'));
const DashboardNominacoes = lazy(() => import('../pages/Nominacoes/Dashboard'));
const RegistrarNominacao = lazy(() => import('../pages/Nominacoes/Register'));
const DetalhesNominacoes = lazy(() => import('../pages/Nominacoes/Details'));

const Desequilibrio = lazy(() => import('../pages/Desequilibrio'));

const Dados = lazy(() => import('../pages/Dados'));

const Acessos = lazy(() => import('../pages/Acessos'));
const RegistrarAcessos = lazy(() => import('../pages/Acessos/Register'));

export const PrivateRoutes: IRoutes[] = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    element: <Dashboard />,
    icon: Icons.DASHBOARD,
    authorizedRoles: [
      'administrador',
      'transportador',
      'regulador',
      'carregador',
    ],
  },
  {
    name: 'Contratos',
    path: '/contratos',
    authorizedRoles: ['administrador', 'transportador', 'carregador'],
    icon: Icons.CONTRATOS,
    children: [
      {
        name: 'Contratos',
        index: true,
        authorizedRoles: ['administrador', 'carregador', 'transportador'],
        element: <Contratos />,
      },
      {
        name: 'Registrar Contrato',
        path: '/contratos/registrar',
        authorizedRoles: ['administrador', 'transportador'],
        element: <RegistrarContratos />,
      },
      {
        name: 'Dashboard Contratos',
        path: '/contratos/dashboard',
        authorizedRoles: ['administrador', 'transportador', 'carregador'],
        element: <DashboardContratos />,
      },
      {
        name: 'Detalhes do Contrato',
        path: '/contratos/visualizar/:id',
        authorizedRoles: ['administrador', 'transportador', 'carregador'],
        element: <DetalhesContratos />,
      },
    ],
  },
  {
    name: 'Nominações',
    path: '/nominacoes',
    authorizedRoles: ['administrador', 'transportador', 'carregador'],
    icon: Icons.NOMINACOES,
    children: [
      {
        name: 'Nominações',
        index: true,
        authorizedRoles: ['administrador', 'carregador', 'transportador'],
        element: <Nominacoes />,
      },
      {
        name: 'Registrar Nominação',
        path: '/nominacoes/registrar',
        element: <RegistrarNominacao />,
        authorizedRoles: ['carregador'],
      },
      {
        name: 'Dashboard Nominações',
        path: '/nominacoes/dashboard',
        element: <DashboardNominacoes />,
        authorizedRoles: ['administrador', 'transportador', 'carregador'],
      },
      {
        name: 'Detalhes da Nominação',
        path: '/nominacoes/visualizar/:id',
        authorizedRoles: ['administrador', 'transportador', 'carregador'],
        element: <DetalhesNominacoes />,
      },
    ],
  },
  {
    name: 'Programações',
    path: '/programacoes',
    authorizedRoles: ['administrador', 'transportador', 'carregador'],
    icon: Icons.PROGRAMACOES,
    children: [
      {
        name: 'Programações',
        index: true,
        authorizedRoles: ['administrador', 'transportador', 'carregador'],
        element: <Programacoes />,
      },
      {
        name: 'Registrar Programação',
        path: '/programacoes/registrar',
        element: <RegistrarProgramacoes />,
        authorizedRoles: ['administrador', 'transportador'],
      },
      {
        name: 'Dashboard Programações',
        path: '/programacoes/dashboard',
        element: <DashboardProgramações />,
        authorizedRoles: ['administrador', 'carregador', 'transportador'],
      },
      {
        name: 'Detalhes da Programação',
        path: '/programacoes/visualizar/:id',
        element: <DetailsProgramacoes />,
        authorizedRoles: ['administrador', 'carregador', 'transportador'],
      },
    ],
  },
  {
    name: 'Saldo de Portfólio',
    path: '/saldoportfolio',
    authorizedRoles: ['administrador', 'transportador', 'carregador'],
    icon: Icons.SALDO_PORTFOLIO,
    element: <SaldoPortfolio />,
  },
  {
    name: 'Registros',
    path: '/registros',
    authorizedRoles: ['administrador', 'transportador', 'carregador'],
    icon: Icons.REGISTROS,
    children: [
      {
        name: 'Registros',
        index: true,
        authorizedRoles: ['administrador', 'transportador', 'carregador'],
        element: <Navigate to="/registros/injecoes" />,
      },
      {
        name: 'Injeções',
        path: '/registros/injecoes',
        element: <RegistroInjecoes />,
        authorizedRoles: ['administrador', 'transportador', 'carregador'],
      },
      {
        name: 'Retiradas',
        path: '/registros/retiradas',
        element: <RegistroRetiradas />,
        authorizedRoles: ['administrador', 'transportador', 'carregador'],
      },
      {
        name: 'Transferências',
        path: '/registros/transferencias',
        element: <RegistroTransferencias />,
        authorizedRoles: ['administrador', 'transportador', 'carregador'],
      },
    ],
  },
  {
    name: 'Gasodutos',
    path: '/gasodutos',
    authorizedRoles: ['administrador', 'transportador'],
    icon: Icons.GASODUTOS,
    children: [
      {
        name: 'Gasodutos',
        index: true,
        element: <Gasodutos />,
        authorizedRoles: ['administrador', 'transportador'],
      },
      {
        name: 'Registrar Gasodutos',
        path: '/gasodutos/registrar',
        element: <RegistrarGasodutos />,
        authorizedRoles: ['administrador', 'transportador'],
      },
    ],
  },
  {
    name: 'Pontos de Transporte',
    path: '/pontostransporte',
    authorizedRoles: ['administrador', 'transportador'],
    icon: Icons.PONTOS_DE_TRANSPORTE,
    children: [
      {
        name: 'Pontos de Transporte',
        index: true,
        element: <PontosTransporte />,
        authorizedRoles: ['administrador', 'transportador'],
      },
      {
        name: 'Registrar Ponto de Transporte',
        path: '/pontostransporte/registrar',
        element: <RegistrarPontosTransporte />,
        authorizedRoles: ['administrador', 'transportador'],
      },
    ],
  },
  {
    name: 'Transportadores',
    path: '/transportadores',
    authorizedRoles: ['administrador'],
    icon: Icons.TRANSPORTADORES,
    children: [
      {
        name: 'Transportadores',
        index: true,
        authorizedRoles: ['administrador'],
        element: <Transportadores />,
      },
      {
        name: 'Registrar Transportadores',
        path: '/transportadores/registrar',
        element: <RegistrarTransportadores />,
        authorizedRoles: ['administrador'],
      },
    ],
  },
  {
    name: 'Carregadores',
    path: '/carregadores',
    authorizedRoles: ['administrador'],
    icon: Icons.CARREGADORES,
    children: [
      {
        name: 'Carregadores',
        index: true,
        authorizedRoles: ['administrador'],
        element: <Carregadores />,
      },
      {
        name: 'Registrar Carregadores',
        path: '/carregadores/registrar',
        element: <RegistrarCarregadores />,
        authorizedRoles: ['administrador'],
      },
    ],
  },
  {
    name: 'Importar e Exportar Dados',
    path: '/dados',
    authorizedRoles: ['administrador'],
    icon: Icons.DADOS,
    children: [
      {
        name: 'Importar e Exportar Dados',
        index: true,
        authorizedRoles: ['administrador'],
        element: <Dados />,
      },
    ],
  },
  {
    name: 'Acessos',
    path: '/acessos',
    authorizedRoles: ['transportador', 'carregador'],
    icon: Icons.ACESSOS,
    children: [
      {
        name: 'Acessos',
        index: true,
        authorizedRoles: ['transportador', 'carregador'],
        element: <Acessos />,
      },
      {
        name: 'Registrar Acesso',
        path: '/acessos/registrar',
        authorizedRoles: ['transportador', 'carregador'],
        element: <RegistrarAcessos />,
      },
    ],
  },
  // {
  //   name: 'Desequilíbrio',
  //   path: '/desequilibrio',
  //   authorizedRoles: ['administrador', 'transportador'],
  //   icon: Icons.DESEQUILIBRIO,
  //   element: <Desequilibrio />,
  // },
  {
    name: 'Gerenciar Usuários',
    path: '/usuarios/:id',
    element: <GerenciamentoUsuarios />,
    authorizedRoles: ['administrador'],
    hidden: true,
  },
  {
    name: 'Registrar Usuários',
    path: '/usuarios/registrar/:id',
    element: <RegistrarUsuario />,
    authorizedRoles: ['administrador'],
    hidden: true,
  },
];
