/* eslint-disable react/require-default-props */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { findUserTypeByValue, useAuth, userType } from '../../context/Auth';

interface ProtectedLayoutProps {
  children: JSX.Element;
  authorizedRoles?: string[];
}

export const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({
  children,
  authorizedRoles,
}) => {
  const { user, logOutOnFail } = useAuth();
  logOutOnFail();
  if (!authorizedRoles) return children;
  if (!user) return <Navigate to="/login" replace />;
  if (!authorizedRoles.includes(userType.get(user.Type)!))
    return <Navigate to="/" replace />;
  return children;
};
