import { useCallback } from 'react';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import get from 'lodash/get';
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteResponse,
} from 'swr/infinite';

import { api } from '../services/api';

export type GetRequest = AxiosRequestConfig | null;

interface InfiniteReturn<Data, Error>
  extends Pick<
    SWRInfiniteResponse<AxiosResponse<Data>, AxiosError<Error>>,
    'isValidating' | 'error' | 'mutate' | 'size' | 'setSize'
  > {
  data: Data[] | undefined;
  response: AxiosResponse<Data>[] | undefined;
  isLoadingInitialData: boolean;
  isLoadingMore: boolean;
  isRefreshing: boolean;
  fetchMore(): Promise<AxiosResponse<Data>[] | undefined> | null;
  isEmpty: boolean;
  isReachingEnd: boolean;
}

export interface InfiniteConfig<Data = unknown, Error = unknown>
  extends SWRInfiniteConfiguration<AxiosResponse<Data>, AxiosError<Error>> {
  dataPath: string;
  limit?: number;
  params?: any;
}

export function useRequestInfinite<Data = unknown, Error = unknown>(
  getRequest: (
    index: number,
    previousPageData: AxiosResponse<Data> | null,
  ) => GetRequest,
  {
    dataPath,
    limit = 10,
    params,
    ...config
  }: InfiniteConfig<Data, Error> = {} as InfiniteConfig<Data, Error>,
): InfiniteReturn<Data, Error> {
  const {
    data: response,
    error,
    isValidating,
    mutate,
    size,
    setSize,
  } = useSWRInfinite<AxiosResponse<Data>, AxiosError<Error>>(
    (index, previousPageData) => {
      const key = getRequest(index, previousPageData);
      const paramsJSON = JSON.stringify({ ...key?.params, limit, ...params });
      const encoded = btoa(paramsJSON);
      return key
        ? JSON.stringify({
            ...key,
            params: { ...key.params, request: encoded.replaceAll('=', '') },
          })
        : null;
    },
    (request: string) => api(JSON.parse(request)),
    {
      ...config,
    },
  );

  const path = `data.${dataPath}`;

  const isEmpty =
    !response ||
    String(get(response, `[0].${path}`))?.length === 0 ||
    get(response, `[0].${path}`) === null;
  const isLoadingInitialData = !response && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    Boolean(size > 0 && response && typeof response[size - 1] === 'undefined');
  const isRefreshing = Boolean(
    isValidating && response && response.length === size,
  );
  const isReachingEnd =
    isEmpty ||
    Boolean(
      response &&
        String(get(response, `[${response?.length - 1}].${path}`))?.length <
          limit,
    );

  const fetchMore = useCallback(() => {
    if (isLoadingMore || isRefreshing || isReachingEnd) return null;

    return setSize((currentSize) => currentSize + 1);
  }, [isLoadingMore, isRefreshing, isReachingEnd, setSize]);

  return {
    data: response?.map((r) => r.data),
    response,
    error,
    isLoadingInitialData,
    isLoadingMore,
    isRefreshing,
    isValidating,
    isEmpty,
    isReachingEnd,
    mutate,
    size,
    setSize,
    fetchMore,
  };
}
