import { AxiosRequestConfig } from 'axios';
import { requestAPI } from '../api';

type EmpresaId = {
  empresaId?: string;
};

type GetUsersParams = EmpresaId & PaginationParams;

export const getUsersInfinite = (
  params?: GetUsersParams,
): AxiosRequestConfig => {
  return {
    method: 'GET',
    url: `/admin/getUsers`,
    params,
  };
};

export const createUsuario = async (
  payload: CreateUsuarioPayload,
): Promise<Result<CreateUsuarioResponse>> => {
  const config: AxiosRequestConfig = {
    url: '/admin/createUser',
    method: 'POST',
  };
  const response = await requestAPI<CreateUsuarioResponse>(config, payload);
  return response;
};

export const deleteUsuario = async (
  payload: DeleteUsuarioPayload,
): Promise<Result<DeleteUsuarioResponse>> => {
  const config: AxiosRequestConfig = {
    url: '/admin/deleteUser',
    method: 'POST',
  };
  const response = await requestAPI<DeleteUsuarioResponse>(config, payload);
  return response;
};

export const redefinirSenha = async (
  payload: RedefinirSenhaPayload,
): Promise<Result<any>> => {
  const config: AxiosRequestConfig = {
    url: '/auth/changePassword',
    method: 'POST',
  };
  const response = await requestAPI<any>(config, payload);
  return response;
};
