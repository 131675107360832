/* eslint-disable react/require-default-props */
import React, { useRef } from 'react';
import { useClickAway } from '../../hooks/useClickAway';
import Button from '../Button';
import { ModalContainer, ModalFooterContainer } from './styles';

export interface IModalProps {
  isOpen: boolean;
  title: string;
  onClose: {
    action(): void;
    label: string;
  };
  onConfirm?: {
    action(): void;
    label: string;
    buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
    loading?: boolean;
  };
  onRefuse?: {
    action(): void;
    label: string;
    buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  };
}

const Modal: React.FC<IModalProps> = ({
  isOpen,
  title,
  onClose,
  onConfirm,
  onRefuse,
  children,
}) => {
  const ref = useRef(null);
  useClickAway(ref, onClose.action);

  return isOpen ? (
    <ModalContainer>
      <div className="br-modal">
        <div className="br-card">
          <div className="container-fluid p-1 p-sm-4">
            <div className="br-modal-header">
              <div className="br-modal-title" title={title}>
                {title}
              </div>
              <Button
                className="br-button close circle small"
                aria-label="Fechar"
                onClick={onClose.action}
              >
                <i className="fas fa-times" aria-hidden="true" />
              </Button>
            </div>
            <div className="br-modal-body">{children}</div>
            <ModalFooterContainer>
              <Button
                variant="secondary"
                density="small"
                onClick={onClose.action}
              >
                {onClose.label}
              </Button>
              {onRefuse && (
                <Button
                  type={onRefuse.buttonType || 'button'}
                  density="small"
                  onClick={onRefuse.action}
                  variant="primary"
                  style={{ backgroundColor: 'red' }}
                >
                  {onRefuse.label}
                </Button>
              )}
              {onConfirm && (
                <Button
                  type={onConfirm.buttonType || 'button'}
                  density="small"
                  onClick={onConfirm.action}
                  loading={onConfirm.loading || false}
                >
                  {onConfirm.label}
                </Button>
              )}
            </ModalFooterContainer>
          </div>
        </div>
      </div>
    </ModalContainer>
  ) : null;
};

export default Modal;
