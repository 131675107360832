/* eslint-disable no-console */
import { AxiosRequestConfig } from 'axios';
import { requestAPI } from '../api';

export const btoaPayload = (payload: any) => {
  return btoa(JSON.stringify({ ...payload })).replaceAll('=', '');
};

export const getAdminDashboardData = async (): Promise<
  Result<AdminDashboardResponse>
> => {
  const config: AxiosRequestConfig = {
    url: '/admin/dashboard/contabilizacao',
    method: 'GET',
  };
  const response = await requestAPI<AdminDashboardResponse>(config);
  return response;
};

// Transportador

export const getTransportadorProgramacoesData = async (
  payload: TransportadorDashboardProgramacoesPayload,
): Promise<Result<TransportadorDashboardProgramacoesResponse>> => {
  const request = btoaPayload(payload);
  const config: AxiosRequestConfig = {
    url: `/transportador/dashboard/programacoes?request=${request}`,
    method: 'GET',
  };
  const response = await requestAPI<TransportadorDashboardProgramacoesResponse>(
    config,
  );
  return response;
};

export const getTransportadorSaldoData = async (
  payload: TransportadorDashboardSaldoPayload,
): Promise<Result<TransportadorDashboardSaldoResponse>> => {
  const request = btoaPayload(payload);
  const config: AxiosRequestConfig = {
    url: `/transportador/dashboard/saldoPortifolio?request=${request}`,
    method: 'GET',
  };
  const response = await requestAPI<TransportadorDashboardSaldoResponse>(
    config,
  );
  return response;
};

export const getTransportadorContratosData = async (
  payload: TransportadorDashboardContratosPayload,
): Promise<Result<TransportadorDashboardContratosResponse>> => {
  const request = btoaPayload(payload);
  const config: AxiosRequestConfig = {
    url: `/transportador/dashboard/qtdeContratosVigentes?request=${request}`,
    method: 'GET',
  };
  const response = await requestAPI<TransportadorDashboardContratosResponse>(
    config,
  );
  return response;
};

// Carregador

export const getCarregadorProgramacoesData = async (
  payload: CarregadorDashboardProgramacoesPayload,
): Promise<Result<CarregadorDashboardProgramacoesResponse>> => {
  const request = btoaPayload(payload);
  const config: AxiosRequestConfig = {
    url: `/carregador/dashboard/programacoes?request=${request}`,
    method: 'GET',
  };
  const response = await requestAPI<CarregadorDashboardProgramacoesResponse>(
    config,
  );
  return response;
};

export const getCarregadorSaldoData = async (
  payload: CarregadorDashboardSaldoPayload,
): Promise<Result<CarregadorDashboardSaldoResponse>> => {
  const request = btoaPayload(payload);
  const config: AxiosRequestConfig = {
    url: `/carregador/dashboard/saldoPortifolio?request=${request}`,
    method: 'GET',
  };
  const response = await requestAPI<CarregadorDashboardSaldoResponse>(config);
  return response;
};

export const getCarregadorContratosData = async (
  payload: CarregadorDashboardContratosPayload,
): Promise<Result<CarregadorDashboardContratosResponse>> => {
  const request = btoaPayload(payload);
  const config: AxiosRequestConfig = {
    url: `/carregador/dashboard/qtdeContratosVigentes?request=${request}`,
    method: 'GET',
  };
  const response = await requestAPI<CarregadorDashboardContratosResponse>(
    config,
  );
  return response;
};

// Header Summary

export const getResumoHeader = async (
  role?: string,
): Promise<Result<ResumoHeaderResponse>> => {
  const config: AxiosRequestConfig = {
    url: `${role || 'admin'}/getHeaderResumo`,
    method: 'GET',
  };
  const response = await requestAPI<ResumoHeaderResponse>(config);
  return response;
};
