/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import { RegistrarUsuariosForm } from '../../components/Forms/RegistrarUsuariosForm';
import { createUsuario } from '../../services/Users';
import { Icons } from '../../utils/icons';
import Toast from '../../components/Toast';
import { useHeader } from '../../context/Header';

export interface RegistrarUsuariosFormData {
  nome: string;
  userName: string;
  password: string;
  confirmPassword: string;
  empresaKey: string;
  adminOrganization?: boolean;
}

const RegistrarUsuarios: React.FC = () => {
  const methods = useForm<RegistrarUsuariosFormData>();
  const navigate = useNavigate();
  const { id } = useParams();

  const { refresh } = useHeader();

  const formSubmitHandler = useCallback(
    async (data: RegistrarUsuariosFormData) => {
      const payload: CreateUsuarioPayload = {
        nome: data.nome,
        userName: data.userName,
        password: data.password,
        passConfirm: data.confirmPassword,
        empresaKey: id || '',
        adminOrganization: `${data.adminOrganization}`,
      };
      console.log(payload);
      const response = await createUsuario(payload);
      if (response.type === 'success') {
        toast(
          <Toast
            type="success"
            title="Usuário criado com sucesso"
            description=""
          />,
        );
        refresh();
        navigate(`/usuarios/${id}`);
      } else {
        toast(
          <Toast
            type="danger"
            title="Erro ao criar usuário"
            description={response.error.message}
          />,
        );
      }
    },
    [id, navigate, refresh],
  );
  return (
    <div className="row">
      <div className="col">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Adicionar Usuário</h2>
          <Button
            onClick={() => navigate(`/usuarios/${id}`)}
            variant="secondary"
          >
            <i className={Icons.USUARIOS} aria-hidden="true" />
            Ver Usuários
          </Button>
        </div>
        <RegistrarUsuariosForm {...methods} onSubmit={formSubmitHandler} />
      </div>
    </div>
  );
};

export default RegistrarUsuarios;
