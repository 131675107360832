/* eslint-disable react/require-default-props */
import React, { forwardRef, InputHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';

import { InputErrorMessage } from '../ErrorMessage';

type InputAttributes = InputHTMLAttributes<HTMLInputElement>;

export interface InputProps extends InputAttributes {
  name: string;
  label: string | JSX.Element;
  errors: FieldError | undefined;
  helpText?: string;
  inputState?: 'success' | 'warning' | 'info' | 'danger';
  icon?: string;
  iconIsClickable?: boolean;
  className?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  props,
  ref,
) {
  const { label, errors, icon, inputState, helpText, className, ...rest } =
    props;

  return (
    <div className={className}>
      <div className={`br-input ${errors ? 'danger' : ''} ${inputState || ''}`}>
        <label htmlFor={props.name}>{label}</label>
        <div className="input-group">
          {icon && (
            <div className="input-icon">
              <i className={icon} aria-hidden="true" />
            </div>
          )}
          <input id={props.name} type="text" {...rest} ref={ref} />

          <InputErrorMessage errors={errors} />
        </div>

        {helpText && <p>{helpText}</p>}
      </div>
    </div>
  );
});
