import React, { lazy } from 'react';
import { IRoutes } from './types';

const NotFound = lazy(() => import('../pages/404'));
const Login = lazy(() => import('../pages/Login'));

export const PublicRoutes: IRoutes[] = [
  {
    path: '/login',
    name: 'Login',
    hidden: true,
    element: <Login />,
  },
  {
    path: '*',
    name: '404',
    hidden: true,
    element: <NotFound />,
  },
];
