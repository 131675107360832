/* eslint-disable no-console */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getResumoHeader } from '../../services/Dashboard';
import { getUserRole } from '../../utils/getUserRole';
import { useAuth } from '../Auth';
import { IHeaderContext } from './Header';

export const HeaderContext = createContext<IHeaderContext>({
  headerData: undefined,
  refresh: async () => null,
  loading: false,
});

export const HeaderProvider = ({ children }: { children: React.ReactNode }) => {
  const [headerData, setHeaderData] = useState<ResumoHeaderResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  const { user: authUser } = useAuth();

  const getHeader = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getResumoHeader(getUserRole(user!));
      if (response.type === 'success') {
        setHeaderData(response.value);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  const refresh = useCallback(async () => {
    getHeader();
    return null;
  }, [getHeader]);

  useEffect(() => {
    if (authUser === null) {
      setUser(null);
    } else {
      setUser(authUser);
    }
  }, [authUser]);

  useEffect(() => {
    if (user !== null) {
      getHeader();
    }
  }, [getHeader, user]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (user !== null) {
        getHeader();
      }
    }, 30000);
    return () => clearInterval(interval);
  }, [getHeader, user]);

  const value = useMemo(
    () => ({
      headerData,
      refresh,
      loading,
    }),
    [headerData, loading, refresh],
  );
  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export function useHeader() {
  const context = useContext(HeaderContext);
  return context;
}
