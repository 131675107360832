import styled from 'styled-components';

type Props = {
  visibility: 'true' | 'false';
};

const displayModifier = {
  true: 'flex',
  false: 'none',
};

export const FunctionalitiesList = styled.div<Props>`
  &.br-list {
    display: flex;

    @media (max-width: 1280px) {
      display: '${({ visibility }) => displayModifier[visibility]}';
    }
  }
`;

export const LinkList = styled.div<Props>`
  div {
    display: ${({ visibility }) => (visibility === 'true' ? 'flex' : 'none')};
    width: 100%;
    gap: 3rem;
  }
`;

export const HeaderBottom = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

export const HeaderMenu = styled.div`
  .header-title {
    font-size: 25px;
    min-width: fit-content;
  }
  .header-subtitle {
    min-width: max-content;
  }
`;

export const HeaderLogo = styled.div``;

export const LogoutSection = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  .logged-user {
    .label {
      color: black;
      font-size: 12px;
      margin: 0;
    }
    .user-name {
      color: black;
      margin: 0;
      font-size: 15px;
    }
  }
`;
