import styled from 'styled-components';

export const ModalFooterContainer = styled.div.attrs({
  className: 'br-modal-footer',
})`
  justify-content: flex-end;
  gap: 1rem;
`;

export const ModalContainer = styled.div.attrs({
  className: 'br-scrim foco active',
})`
  overflow: hidden;

  &&& {
    .br-modal {
      padding: 0;

      @media (max-width: 900px) {
        width: 450px;
        max-width: 90%;
      }
    }
  }
`;
