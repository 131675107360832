/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-access-key */
import React, { Children, useCallback, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useMatch } from 'react-router-dom';
import { useAuth } from '../../context/Auth';
// import { useAuth } from '../../context/Auth';
import { PrivateRoutes } from '../../routes/PrivateRoutes';
import { IRoutes } from '../../routes/types';

// import { useAuthorizedRoutes } from '../../hooks/useAuthorizedRoutes';
import Breadcrumb from '../Breadcrumb';
import { FixedNav } from '../FixedNav';
import Header from '../Header';
import { MainContent } from './styles';

export const Layout: React.FC = () => {
  const location = useLocation();
  const match = useMatch(location.pathname);
  const { user } = useAuth();

  const setIndexToPath = (childrenRoute: IRoutes[], parentRoute: IRoutes) => {
    return childrenRoute.map((route) =>
      route.index
        ? {
            ...route,
            path: parentRoute.path,
            name: parentRoute.name,
          }
        : route,
    );
  };

  const flatRoutes = useCallback((routes: IRoutes[]) => {
    const flattenRoutes = routes
      .map((route) =>
        route.children ? setIndexToPath(route.children, route).flat() : route,
      )
      .flat();
    return flattenRoutes;
  }, []);

  const newCrumbs = useMemo(() => {
    const routes = flatRoutes(PrivateRoutes);
    const filteredRoutes = routes.filter((route) =>
      match?.pathname.includes(route.path!),
    );
    return filteredRoutes;
  }, [flatRoutes, match]);

  return (
    <div className="template-base">
      <Header />

      <main className="d-flex flex-fill" id="main">
        <div className="container-fluid p-0 d-flex">
          <div className="col">
            <MainContent>
              {user && <FixedNav />}
              <div className="col p-5">
                {user && (
                  <Breadcrumb
                    crumbs={newCrumbs.map((crumb) => ({
                      name: crumb.name,
                      path: crumb.path,
                    }))}
                  />
                )}
                <Outlet />
              </div>
            </MainContent>
          </div>
        </div>
      </main>

      <footer className="br-footer p-3" id="footer">
        <div className="container-lg">
          <div className="info">
            <div className="text-down-01 text-medium">
              Texto destinado a exibição de informações relacionadas à&nbsp;
              <strong>
                <a href="#">licença de uso</a>.
              </strong>
            </div>
          </div>
        </div>
      </footer>
      <div className="br-cookiebar default d-none" tabIndex={-1} />
    </div>
  );
};
