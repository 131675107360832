export enum Icons {
  DASHBOARD = 'fas fa-signal',
  GASODUTOS = 'fab fa-empire',
  PONTOS_DE_TRANSPORTE = 'fas fa-map-marked-alt',
  TRANSPORTADORES = 'fas fa-city',
  CARREGADORES = 'fas fa-industry',
  PROGRAMACOES = 'fas fa-business-time',
  SALDO_PORTFOLIO = 'fab fa-buffer',
  REGISTROS = 'fas fa-clipboard',
  CONTRATOS = 'fas fa-file-contract',
  NOMINACOES = 'fas fa-file-signature',
  DESEQUILIBRIO = 'fas fa-wallet',
  GERENCIAMENTO = 'fas fa-user-cog',
  NOVO_USUARIO = 'fas fa-user-plus',
  USUARIOS = 'fas fa-users',
  DADOS = 'fas fa-database',
  ACESSOS = 'fas fa-key',
}
