import React, { Suspense } from 'react';

import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { Layout } from './components/Layout';
import Loading from './components/Loading';
import { ProtectedLayout } from './components/ProtectedLayout';
import { useAuth } from './context/Auth';
import { PrivateRoutes } from './routes/PrivateRoutes';
import { PublicRoutes } from './routes/PublicRoutes';
import { IRoutes } from './routes/types';

const createRoutes = (routes: IRoutes[]): RouteObject[] => {
  if (!routes) return [];
  return routes.map((route) => ({
    ...route,
    ...(route?.element && {
      element: (
        <ProtectedLayout authorizedRoles={route.authorizedRoles}>
          <Suspense fallback={<Loading />}>{route.element}</Suspense>
        </ProtectedLayout>
      ),
    }),
    children: route?.children && createRoutes(route.children),
  }));
};

const App: React.FC = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          index: true,
          element: (
            <ProtectedLayout>
              <Suspense fallback={<Loading />}>
                <Navigate to="/dashboard" />
              </Suspense>
            </ProtectedLayout>
          ),
        },
        ...createRoutes(PrivateRoutes),
        ...createRoutes(PublicRoutes),
      ],
    },
  ]);
  return routes;
};

export default App;
