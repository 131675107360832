/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-console */
import axios, { AxiosRequestConfig } from 'axios';
import { useAuth } from '../context/Auth';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
  withCredentials: true,
});

export { api };

export const requestAPI = async <R, P = {}>(
  config: AxiosRequestConfig,
  payload?: P,
): Promise<Result<R>> => {
  try {
    const response = await api.request<R>({
      ...config,
      data: payload,
    });
    return { type: 'success', value: response.data };
  } catch (error) {
    return { type: 'error', error: error as Error };
  }
};
