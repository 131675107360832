import React, { useState, useEffect, useCallback } from 'react';

import Logo from '../../assets/GoGasByGoLedger.png';
import LogoSmall from '../../assets/GoGasSmall.png';
import { useAuth } from '../../context/Auth';
import { HeaderSummary } from '../HeaderSummary';
import Menu from '../Menu';

import {
  LinkList,
  FunctionalitiesList,
  HeaderBottom,
  HeaderMenu,
  HeaderLogo,
  LogoutSection,
} from './styles';

const Header: React.FC = () => {
  const [variant, setVariant] = useState('');
  const [openFastAccess, setOpenFastAccess] = useState(true);
  const [sytemFunctionalities, setSytemFunctionalities] = useState(false);

  const { user, logout } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      const height = document.getElementById('header')?.offsetHeight;

      if (height && window.pageYOffset > height) {
        setVariant('sticky compact');
        setOpenFastAccess(false);
      } else {
        setVariant('');
        setOpenFastAccess(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`br-header ${variant}`}
      id="header"
      data-sticky="data-sticky"
    >
      <div className="container-lg" style={{ position: 'relative' }}>
        <div className="header-top">
          <HeaderLogo
            className="header-logo"
            style={
              variant === 'sticky compact'
                ? {
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    height: '100%',
                    width: '200px',
                  }
                : {}
            }
          >
            {variant === '' ? (
              <img src={Logo} alt="logo" />
            ) : (
              <img
                src={LogoSmall}
                alt="logo"
                style={{ transform: 'scale(3) translateX(25%)' }}
              />
            )}

            <span className="br-divider vertical mx-half mx-sm-1" />
            <div className="header-sign">Gestão de Gás Natural</div>
          </HeaderLogo>
          <div className="header-actions">
            <div className="header-links dropdown">
              <LinkList visibility={String(openFastAccess) as 'false' | 'true'}>
                <div>
                  <a href="/">Sobre o GoGas</a>
                  <a href="/">Tutoriais</a>
                  <a href="/">Suporte</a>
                  <a href="/">Fale conosco</a>
                </div>
              </LinkList>
            </div>
            <div
              className="header-login"
              style={user! ? { position: 'absolute', top: '50%' } : {}}
            >
              <div className="header-sign-in">
                {!user?.Name ? (
                  <button
                    className="br-button sign-in small"
                    type="button"
                    data-trigger="login"
                  >
                    <i className="fas fa-user" aria-hidden="true" />
                    <span className="d-sm-inline">Entrar</span>
                  </button>
                ) : (
                  <LogoutSection>
                    {user && (
                      <div className="logged-user">
                        <p className="label">Usuário:</p>
                        <p className="user-name">{user.UserName}</p>
                      </div>
                    )}
                    <button
                      className="br-button sign-in small"
                      type="button"
                      data-trigger="login"
                      onClick={() => logout()}
                    >
                      <i className="fas fa-user" aria-hidden="true" />
                      <span className="d-sm-inline">Sair</span>
                    </button>
                  </LogoutSection>
                )}
              </div>
              <div className="header-avatar d-none">
                <div className="avatar dropdown">
                  <span className="br-avatar" title="Fulana da Silva">
                    <span className="image">
                      <img
                        src="https://picsum.photos/id/823/400"
                        alt="Avatar"
                      />
                    </span>
                  </span>
                  <button
                    className="br-button circle small"
                    type="button"
                    aria-label="Abrir Menu de usuário"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-angle-down" aria-hidden="true" />
                  </button>
                  <div className="br-notification show">
                    <div className="notification-header">
                      <div className="row">
                        <div className="col-10">
                          <span className="text-bold">Fulano da Silva</span>
                          <br />
                          <small>
                            <a href="/">nome.sobrenome@dominio.gov</a>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="notification-body">
                      <div className="br-tab">
                        <nav className="tab-nav">
                          <ul>
                            <li className="tab-item">
                              <button
                                type="button"
                                data-panel="notif-item805"
                                aria-label="notif-item805"
                              >
                                <span className="name">
                                  <span className="icon">
                                    <i
                                      className="fas fa-image"
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span className="name">Item</span>
                                </span>
                              </button>
                            </li>
                            <li className="tab-item is-active">
                              <button
                                type="button"
                                data-panel="notif-item806"
                                aria-label="notif-item806"
                              >
                                <span className="name">
                                  <span className="icon">
                                    <i
                                      className="fas fa-image"
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span className="name">Item</span>
                                </span>
                              </button>
                            </li>
                          </ul>
                        </nav>
                        <div className="tab-content">
                          <div className="tab-panel" id="notif-item805">
                            <div className="br-list">
                              <button className="br-item" type="button">
                                <i
                                  className="fas fa-heartbeat"
                                  aria-hidden="true"
                                />
                                Link de Acesso
                              </button>
                              <span className="br-divider" />
                              <button className="br-item" type="button">
                                <i
                                  className="fas fa-heartbeat"
                                  aria-hidden="true"
                                />
                                Link de Acesso
                              </button>
                              <span className="br-divider" />
                              <button className="br-item" type="button">
                                <i
                                  className="fas fa-heartbeat"
                                  aria-hidden="true"
                                />
                                Link de Acesso
                              </button>
                            </div>
                          </div>
                          <div
                            className="tab-panel is-active"
                            id="notif-item806"
                          >
                            <div className="br-list">
                              <button className="br-item" type="button">
                                <span className="br-tag status small warning" />
                                <span className="text-bold">Título</span>
                                <span className="text-medium mb-2">
                                  25 de out
                                </span>
                                <span>
                                  Nostrud consequat culpa ex mollit aute. Ex ex
                                  veniam ea labore laboris duis duis elit. Ex
                                  aute dolor enim aute Lorem dolor. Duis labore
                                  ad anim culpa. Non aliqua excepteur sunt
                                  eiusmod ex consectetur ex esse laborum velit
                                  ut aute.
                                </span>
                              </button>
                              <span className="br-divider" />
                              <button className="br-item" type="button">
                                <span className="text-bold">Título</span>
                                <span className="text-medium mb-2">
                                  24 de out
                                </span>
                                <span>
                                  Labore nulla elit laborum nulla duis. Deserunt
                                  ad nulla commodo occaecat nulla proident ea
                                  proident aliquip dolore sunt nulla. Do sit eu
                                  consectetur quis culpa. Eiusmod minim irure
                                  sint nulla incididunt occaecat ipsum mollit in
                                  ut. Minim adipisicing veniam adipisicing velit
                                  nostrud duis consectetur aute nulla deserunt
                                  culpa aliquip.
                                </span>
                              </button>
                              <span className="br-divider" />
                              <button className="br-item" type="button">
                                <span className="br-tag status small warning" />
                                <span className="text-bold">Título</span>
                                <span className="text-medium mb-2">
                                  03 de out
                                </span>
                                <span>
                                  Duis qui dolor dolor qui sint consectetur.
                                  Ipsum eu dolore ex anim reprehenderit laborum
                                  commodo. Labore do ut nulla eiusmod
                                  consectetur.
                                </span>
                              </button>
                              <span className="br-divider" />
                              <button className="br-item" type="button">
                                <span className="text-bold">Título</span>
                                <span className="text-medium mb-2">
                                  16 de mai
                                </span>
                                <span>
                                  Sunt velit dolor enim mollit incididunt irure
                                  est. Ad ea Lorem culpa quis occaecat sunt in
                                  exercitation nisi. Sit laborum laborum dolor
                                  culpa ipsum velit. Non nulla nisi dolore et
                                  anim consequat officia deserunt amet qui.
                                  Incididunt exercitation irure labore ut Lorem
                                  culpa. Dolore ea irure pariatur ullamco culpa
                                  veniam amet dolor in fugiat pariatur ut. Sit
                                  non ut enim et incididunt tempor irure
                                  pariatur ex proident labore cillum dolore
                                  nisi.
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HeaderBottom>{user !== null && <HeaderSummary />}</HeaderBottom>
      </div>
    </header>
  );
};

export default Header;
