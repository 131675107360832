/* eslint-disable react/require-default-props */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Item } from './styles';

interface NavItemProps {
  icon: string;
  text: string;
  path?: string;
}

export const NavItem: React.FC<NavItemProps> = ({ icon, text, path }) => {
  const navigate = useNavigate();
  return (
    <Item onClick={() => navigate(`${path}`)}>
      <div>
        <i className={`${icon}`} />
        <p>{text}</p>
      </div>
      <i className="right-arrow fas fa-chevron-right" />
    </Item>
  );
};
