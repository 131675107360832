/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TableContainer, TableContainerInside } from './styles';
import Button from '../Button';
import Loading from '../Loading';
import { TableCheckBox } from '../../pages/Contratos/styles';
import { Disabled } from '../Button/stories';

export interface ITableProps {
  checkAll?: {
    check: () => void;
    disabled: boolean;
    checked: boolean;
  };
  thead: string[] | Element[];
  caption: string;
  refresh?: () => void;
  fetchMore?: () => void;
  hasMore?: boolean;
  isLoading?: boolean;
  data: any;
}

const fetchMoreFallback = () => ({});

type TDensity = 'small' | 'medium' | 'large';

const Table: React.FC<ITableProps> = ({
  thead,
  caption,
  children,
  refresh,
  fetchMore,
  hasMore,
  isLoading,
  data,
  checkAll,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [tableDensity, setTableDensity] = useState<TDensity>('medium');

  return (
    <TableContainer
      className={`br-table ${tableDensity}`}
      data-search="data-search"
      data-selection="data-selection"
    >
      <div className="table-header">
        <div className="top-bar">
          <div className="table-title">{caption}</div>
          <div className="actions-trigger dropdown">
            {refresh && (
              <Button
                className="br-button circle"
                onClick={() => refresh()}
                title="Recarregar"
              >
                <i className="fas fa-sync-alt" aria-hidden="true" />
              </Button>
            )}
            <Button
              className="br-button circle"
              onClick={() => setShowOptions(!showOptions)}
              title="Ver mais opções"
              data-toggle="dropdown"
              aria-label="Ver mais opções"
              aria-expanded={showOptions}
            >
              <i className="fas fa-ellipsis-v" aria-hidden="true" />
            </Button>
            <div
              className={` br-list ${showOptions ? 'd-flex' : 'd-none'}`}
              role="menu"
              onClick={() => setShowOptions(false)}
              onKeyUp={() => setShowOptions(false)}
              tabIndex={0}
            >
              <Button
                className="br-item"
                data-density="small"
                aria-label="Densidade alta"
                onClick={() => setTableDensity('small')}
              >
                Densidade baixa
              </Button>
              <Button
                className="br-item"
                data-density="medium"
                aria-label="Densidade média"
                onClick={() => setTableDensity('medium')}
              >
                Densidade média
              </Button>
              <Button
                className="br-item"
                data-density="large"
                aria-label="Densidade baixa"
                onClick={() => setTableDensity('large')}
              >
                Densidade alta
              </Button>
            </div>
          </div>
        </div>
        <div className="search-bar">
          <div className="br-input">
            <label htmlFor="table-searchbox-18949">Buscar</label>
            <input
              id="table-searchbox-18949"
              type="text"
              placeholder="Buscar na tabela"
            />
            <Button className="br-button circle" aria-label="Buscar">
              <i className="fas fa-search" aria-hidden="true" />
            </Button>
          </div>
          <Button
            className="br-button circle"
            data-dismiss="search"
            aria-label="Fechar busca"
          >
            <i className="fas fa-times" aria-hidden="true" />
          </Button>
        </div>
        <div className="selected-bar">
          <div className="info">
            <span className="count">0</span>
            <span className="text">item selecionado</span>
          </div>
          <div className="actions-trigger">
            <Button
              className="br-button circle inverted"
              title="Ver mais opções"
              data-toggle="dropdown"
              aria-label="Ver mais opções"
            >
              <i className="fas fa-ellipsis-v" aria-hidden="true" />
            </Button>
            <div className="br-list">
              <Button
                className="br-item"
                data-toggle="check-all"
                aria-label="Selecionar todos"
              >
                Selecionar todos
              </Button>
              <span className="br-divider" />
              <Button className="br-item" aria-label="Excluir">
                Excluir
              </Button>
            </div>
          </div>
        </div>
      </div>

      <TableContainerInside id="table-container">
        <InfiniteScroll
          dataLength={data?.length || 0}
          next={fetchMore || fetchMoreFallback}
          hasMore={!hasMore}
          loader={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '1rem',
              }}
            >
              {isLoading && <Loading />}
            </div>
          }
          endMessage={
            !isLoading && (
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                }}
              >
                Fim dos registros
              </div>
            )
          }
          scrollableTarget="table-container"
        >
          <table>
            <caption>{caption}</caption>
            <thead>
              <tr>
                {checkAll && (
                  <th>
                    <TableCheckBox>
                      <label htmlFor="checkAllItems">
                        <input
                          className="input-field"
                          id="checkAllItems"
                          type="checkbox"
                          checked={checkAll.checked && !checkAll.disabled}
                          onChange={() => checkAll.check()}
                          disabled={checkAll.disabled}
                        />
                        <div className="input-div">
                          <div className="mark" />
                        </div>
                      </label>
                    </TableCheckBox>
                  </th>
                )}
                {thead.map((title, idx) => (
                  <th scope="col" key={idx}>
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            {isLoading && !data && (
              <td colSpan={thead.length} style={{ width: '100%' }}>
                <Loading />
              </td>
            )}
            <tbody>{children}</tbody>
          </table>
        </InfiniteScroll>
      </TableContainerInside>
    </TableContainer>
  );
};

export default Table;
