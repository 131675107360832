import styled from 'styled-components';

interface FormOrganizerProps {
  columns?: number;
  padding?: number;
}

export const FormOrganizer = styled.div<FormOrganizerProps>`
  padding: ${(props) => (props.padding ? `${props.padding}px` : '0')};
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : '1fr 1fr 1fr'};
  gap: 16px;
`;
