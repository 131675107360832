/* eslint-disable react/require-default-props */
import React, { ButtonHTMLAttributes } from 'react';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'circle';
  inverted?: boolean;
  density?: 'small' | 'large';
  block?: boolean;
  loading?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  children,
  variant = 'primary',
  density = '',
  inverted,
  block = false,
  loading = false,
  ...rest
}) => {
  const className = `br-button ${variant} ${inverted ? 'inverted' : ''} 
  ${density} ${block ? 'block' : ''} ${loading ? 'loading' : ''}`;

  return (
    <button className={className} type="button" disabled={loading} {...rest}>
      {children}
    </button>
  );
};
export default Button;
