import React from 'react';
import { useAuthorizedRoutes } from '../../hooks/useAuthorizedRoutes';
import { NavItem } from './NavItem';
import { Nav } from './styles';

export const FixedNav: React.FC = () => {
  const authorizedRoutes = useAuthorizedRoutes();
  return (
    <Nav>
      {authorizedRoutes.map((item, index) => {
        if (!item.hidden) {
          return (
            <NavItem
              key={index}
              icon={item.icon ? item.icon : 'fas fa-city'}
              text={item.name}
              path={item.path}
            />
          );
        }
        return null;
      })}
    </Nav>
  );
};
