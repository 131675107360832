import styled from 'styled-components';

export const TableCheckBox = styled.div`
  width: min-content;
  label {
    .input-div {
      width: 23px;
      height: 23px;
      border-radius: 4px;
      border: 1px solid #a5a5a5;
      padding: 2px;
    }
    &:hover {
      .input-div {
        background-color: #ccc;
      }
    }
    .input-field {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }
    .input-field:checked + .input-div .mark {
      background-color: var(--blue);
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
`;
