/* eslint-disable no-console */
import React, {
  createContext,
  useMemo,
  useContext,
  useCallback,
  useState,
} from 'react';
import { IFiltersContext } from './Filters';

export const FiltersContext = createContext<IFiltersContext>({
  filtersState: false,
  setTrueFilters: () => null,
  setFalseFilters: () => null,
});

export const FiltersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [filtersState, setFiltersState] = useState<boolean>(true);

  const setTrueFilters = useCallback(() => {
    setFiltersState(true);
    return null;
  }, []);

  const setFalseFilters = useCallback(() => {
    setFiltersState(false);
    return null;
  }, []);

  const value = useMemo(
    () => ({
      filtersState,
      setTrueFilters,
      setFalseFilters,
    }),
    [filtersState, setFalseFilters, setTrueFilters],
  );

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};

export function useFilters() {
  const context = useContext(FiltersContext);
  return context;
}
