/* eslint-disable react/require-default-props */
import React, { forwardRef, InputHTMLAttributes, useState } from 'react';
import { FieldError } from 'react-hook-form';

import { InputErrorMessage } from '../ErrorMessage';
import { FieldInfo } from './styles';

type InputAttributes = InputHTMLAttributes<HTMLInputElement>;

export interface InputProps extends InputAttributes {
  name: string;
  label: string | JSX.Element;
  errors: FieldError | undefined;
  helpText?: string;
  inputState?: 'success' | 'warning' | 'info' | 'danger';
  icon?: string;
  iconIsClickable?: boolean;
  className?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, InputProps>(function Input(
  props,
  ref,
) {
  const { label, errors, icon, inputState, helpText, className, ...rest } =
    props;

  const [showInfoMessage, setShowInfoMessage] = useState(false);

  return (
    <div className={className}>
      <div className={`${errors ? 'danger' : ''} ${inputState || ''}`}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label className="mr-2" htmlFor={props.name}>
            {label}
          </label>
          <input
            style={{ width: '30px', height: '30px' }}
            id={props.name}
            type="checkbox"
            {...rest}
            ref={ref}
          />
          {helpText && (
            <FieldInfo>
              <i
                className="fas fa-info-circle"
                onMouseEnter={() => setShowInfoMessage(true)}
                onMouseLeave={() => setShowInfoMessage(false)}
              />
              {showInfoMessage && <div className="infoMessage">{helpText}</div>}
            </FieldInfo>
          )}
        </div>
        <InputErrorMessage errors={errors} />
      </div>
    </div>
  );
});
