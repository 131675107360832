import { AxiosRequestConfig } from 'axios';
import { requestAPI } from '../api';

export const getAtorByKey = async (
  key: string,
): Promise<Result<AtorResult>> => {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `/admin/getAtorByKey/${key}`,
  };
  const response = requestAPI<AtorResult>(config);
  return response;
};
