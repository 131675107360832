/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useHeader } from '../../context/Header';
import { HeaderSummaryContainer, HeaderSummaryItem } from './styles';
import Loading from '../Loading';

export const HeaderSummary: React.FC = () => {
  const { headerData, loading } = useHeader();
  const navigate = useNavigate();

  return (
    <HeaderSummaryContainer>
      <div style={{ position: 'absolute' }}>{loading && <Loading />}</div>

      <HeaderSummaryItem onClick={() => navigate('/programacoes')}>
        <div className="title">
          <p>
            Programações <br /> pendentes
          </p>
        </div>
        <div className="amount">
          {!loading && <p>{headerData?.pendentes}</p>}
          {headerData?.pendentes! > 0 && <div className="badge" />}
        </div>
      </HeaderSummaryItem>

      <div className="divider" />

      <HeaderSummaryItem onClick={() => navigate('/contratos')}>
        <div className="title">
          <p>
            Contratos <br /> vigentes
          </p>
        </div>
        <div className="amount">
          {!loading && <p>{headerData?.vigentes}</p>}
          {/* <div className="badge" /> */}
        </div>
      </HeaderSummaryItem>

      <HeaderSummaryItem onClick={() => navigate('/nominacoes')}>
        <div className="title">
          <p>
            Nominações <br /> não utilizadas
          </p>
        </div>
        <div className="amount">
          {!loading && <p>{headerData?.naoUsados}</p>}
          {/* <div className="badge" /> */}
        </div>
      </HeaderSummaryItem>
    </HeaderSummaryContainer>
  );
};
