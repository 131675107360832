import React, { useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { RegistrarUsuariosFormData } from '../../../pages/Usuarios/Register';
import Button from '../../Button';
import { Checkbox } from '../../FormField/Checkbox';
import { Input } from '../../FormField/Input';
import { FormOrganizer } from '../styles';

type RegistrarUsuariosFormProps = UseFormReturn<RegistrarUsuariosFormData> & {
  onSubmit: (data: RegistrarUsuariosFormData) => void;
};

export const RegistrarUsuariosForm: React.FC<RegistrarUsuariosFormProps> = ({
  onSubmit,
  register,
  handleSubmit,
  watch,
  formState: { errors, isSubmitting },
}) => {
  const password = useRef({});
  password.current = watch('password', '');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="col-sm p-0">
        <FormOrganizer
          style={{
            width: 'max-content',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Input
            label="Nome"
            {...register('nome', {
              required: 'Este campo é obrigatório',
              minLength: {
                value: 2,
                message: 'Nome deve ter pelo menos 2 caracteres',
              },
            })}
            className="mb-3"
            icon="fas fa-bars"
            placeholder="Insira o nome"
            errors={errors?.nome}
          />
          <Checkbox
            label="Administrador?"
            {...register('adminOrganization')}
            type="checkbox"
            className="mb-3"
            errors={errors?.adminOrganization}
            helpText="Este usuário será administrador desta organização"
          />
        </FormOrganizer>

        <h6 style={{ fontWeight: 'bold' }}>CREDENCIAIS DE ACESSO AO SISTEMA</h6>

        <FormOrganizer>
          <Input
            label="Login de Acesso"
            {...register('userName', {
              required: 'Este campo é obrigatório',
              minLength: {
                value: 8,
                message: 'Login de acesso deve ter pelo menos 8 caracteres',
              },
              maxLength: {
                value: 25,
                message: 'Login de acesso deve ter no máximo 25 caracteres',
              },
            })}
            className="mb-3"
            icon="fas fa-bars"
            placeholder="Inserir o login de acesso"
            errors={errors?.userName}
          />
          <Input
            label="Senha"
            type="password"
            {...register('password', {
              required: 'Este campo é obrigatório',
              minLength: {
                value: 8,
                message: 'O número mínimo de caracteres é 8.',
              },
              maxLength: {
                value: 16,
                message: 'O número máximo de caracteres é 16.',
              },
            })}
            className="mb-3"
            icon="fas fa-bars"
            placeholder="Inserir a senha"
            errors={errors?.password}
          />
          <Input
            label="Confirmação de Senha"
            type="password"
            {...register('confirmPassword', {
              validate: (value) =>
                value === password.current || 'As senhas devem coincidir',
              required: 'Este campo é obrigatório',
              minLength: {
                value: 8,
                message: 'O número mínimo de caracteres é 8.',
              },
              maxLength: {
                value: 16,
                message: 'O número máximo de caracteres é 16.',
              },
            })}
            className="mb-3"
            icon="fas fa-bars"
            placeholder="Inserir a senha"
            errors={errors?.confirmPassword}
          />
        </FormOrganizer>
      </div>
      <div className="mt-4 w-100 d-flex justify-content-end">
        <Button type="submit" density="large" loading={isSubmitting}>
          <i className="fas fa-plus mr-1" aria-hidden="true" />
          Adicionar usuário
        </Button>
      </div>
    </form>
  );
};
