import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { StyledToastContainer } from './components/Toast/styles';
import GlobalStyle from './styles/global';

import App from './App';
import { AuthProvider } from './context/Auth';
import { HeaderProvider } from './context/Header';
import { FiltersProvider } from './context/Filters';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <HeaderProvider>
        <FiltersProvider>
          <BrowserRouter>
            <StyledToastContainer />
            <GlobalStyle />
            <App />
          </BrowserRouter>
        </FiltersProvider>
      </HeaderProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
