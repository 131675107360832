import styled from 'styled-components';

export const HeaderSummaryContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 2rem;

  .divider {
    width: 2px;
    height: 30px;
    background-color: var(--gray);
  }
`;

export const HeaderSummaryItem = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 10px;
  box-sizing: border-box;
  transition: 0.3s;
  position: relative;

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    z-index: 99;
    opacity: 0.3;
  }

  &:hover {
    background-color: #f7f7f7;
    border-radius: 8px;
    transition: 0.3s;
    cursor: pointer;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
  .badge {
    position: absolute;
    background-color: red;
    width: 10px;
    height: 10px;
    top: -5%;
    right: -5%;
    border-radius: 100%;
  }
  .amount {
    position: relative;
    background-color: var(--gray);
    padding: 2px 4px 5px 5px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
  }
`;
