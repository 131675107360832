import styled from 'styled-components';

export const FieldInfo = styled.div`
  color: var(--blue);
  position: relative;

  .infoMessage {
    color: white;
    position: absolute;
    background-color: var(--blue);
    padding: 8px;
    border-radius: 4px;
    width: max-content;
  }
`;
