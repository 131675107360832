import React from 'react';
import { Link } from 'react-router-dom';

export interface IBreadcrumbProps {
  crumbs: {
    name: string;
    path: string | undefined;
  }[];
}

const Breadcrumb: React.FC<IBreadcrumbProps> = ({ crumbs }) => {
  return (
    <div className="br-breadcrumb col pt-1 pl-0 pr-0 mb-3xh">
      <ul className="crumb-list text-semi-bold text-down-01">
        <li className="crumb home">
          <Link to="/" className="br-button circle">
            <span className="sr-only">Página inicial</span>
            <i className="fas fa-home" />
          </Link>
        </li>
        {crumbs.map(({ name, path }, key) =>
          key + 1 === crumbs.length ? (
            <li className="crumb" key={key}>
              <i className="icon fas fa-chevron-right mr-half" />
              <span key={key}>{name}</span>
            </li>
          ) : (
            <li className="crumb text-" key={key}>
              <i className="icon fas fa-chevron-right mr-half" />
              <Link to={path as string}>{name}</Link>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default Breadcrumb;
