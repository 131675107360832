/* eslint-disable no-console */
import React, {
  createContext,
  useMemo,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { IAuthContext, SignOutResponse } from './Auth';
import Toast from '../../components/Toast';
import { requestAPI } from '../../services/api';
// import { users } from '../../services/Users/mock';

export const AuthContext = createContext<IAuthContext>({
  user: null,
  login: async () => ({} as User),
  logout: async () => ({} as SignOutResponse),
  getUserData: async () => ({} as User),
  logOutOnFail: async () => null,
});

export const userType = new Map<string, string>([
  ['255', 'administrador'],
  ['2', 'carregador'],
  ['4', 'transportador'],
]);

export const findUserTypeByValue = (key: string) => {
  const type = [...userType].find((item) => item[1].includes(key));
  if (type) return type[0];
  return null;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);

  const login = useCallback(
    async (credentials: UserCredentials): Promise<User | null> => {
      const response = await requestAPI<User>(
        { url: '/auth/signIn', method: 'POST' },
        credentials,
      );
      if (response.type === 'success') {
        toast(
          <Toast type="success" title="Você entrou no GoGas" description="" />,
        );
        setUser(response.value);
        return response.value;
      }
      toast(
        <Toast
          type="danger"
          title="Erro ao fazer login"
          description={response.error.message}
        />,
      );
      return null;
    },
    [],
  );

  const logout = useCallback(async (): Promise<SignOutResponse | null> => {
    const response = await requestAPI<SignOutResponse>({
      url: '/auth/signOut',
      method: 'GET',
    });
    if (response.type === 'success') {
      setUser(null);
      toast(<Toast type="info" title="Você saiu do GoGas" description="" />);
      return response.value;
    }
    toast(
      <Toast
        type="danger"
        title="Erro ao fazer logout"
        description={response.error.message}
      />,
    );
    return null;
  }, []);

  const getUserData = useCallback(async () => {
    const response = await requestAPI<User>({ url: '/getUserDataLogged' });
    if (response.type === 'success') setUser(response.value);
  }, []);

  const logOutOnFail = useCallback(async () => {
    const response = await requestAPI<User>({ url: '/getUserDataLogged' });
    if (response.type === 'error') setUser(null);
  }, []);

  useEffect(() => {
    if (user === null) {
      getUserData();
    }
  }, [getUserData, user]);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      getUserData,
      logOutOnFail,
    }),
    [getUserData, logOutOnFail, login, logout, user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
