import styled from 'styled-components';

export const Nav = styled.div`
  background-color: #1351b4;
  width: fit-content;
  padding: 8px;
  color: white;
  border-radius: 0 8px 0 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Item = styled.div`
  background-color: #fff;
  height: 55px;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 12px;
  color: black;
  border-radius: 8px;
  justify-content: space-between;
  font-family: 'Rawline';
  transition: 0.3s;

  &:hover {
    background-color: var(--gray);
  }

  .right-arrow {
    color: #1351b4;
  }

  div {
    display: flex;
    align-items: center;
    gap: 8px;

    i {
      font-size: 14px;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }
`;
